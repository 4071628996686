@import '~antd/dist/reset.css';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');


.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  
}

.ant-layout-sider-children .ant-menu {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ant-drawer-body .ant-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 16px;
}

.ant-table {
  box-shadow: 0px 0px 16px #0016282b;
}

.ant-table-content {
  overflow-x: scroll;
}

.ant-layout {
  background: unset;
}
